import React from "react"
import Header from "../header"
import CookieConsent from "../cookie-consent"
import Footer from "../footer"
import * as styles from "./layout.module.scss"

const Layout = ({
  children,
  showHeaderCtaButton = false,
  footerChildren,
  mobileStickyScrollPosition = -500,
  readingIndicatorElementRef,
  articleHeader,
}) => (
  <>
    <Header
      showCtaButton={showHeaderCtaButton}
      mobileStickyScrollPosition={mobileStickyScrollPosition}
      readingIndicatorElementRef={readingIndicatorElementRef}
      article={articleHeader}
    />
    <CookieConsent />
    <main className={styles.main}>{children}</main>
    <Footer>{footerChildren}</Footer>
  </>
)

export default Layout
