import React from "react"
import Link from "../link"
import * as styles from "./cookie-consent.module.scss"

export default function CookieConsent() {
  const [consent, setConsent] = React.useState(undefined)

  React.useEffect(() => {
    setConsent(localStorage.getItem("consent"))
  }, [])

  const handleConsent = e => {
    e.preventDefault()
    localStorage.setItem("consent", "true")
    setConsent(true)
  }

  if (!consent) {
    return (
      <div className={styles.cookie}>
        <div className={styles.container}>
          We use cookies to give you the best online experience. By using our
          website you agree to our use of cookies in accordance with our{" "}
          <Link to="/cookie-policy">cookie policy.</Link>{" "}
          <Link onClick={e => handleConsent(e)} to="#" className={styles.close}>
            Close
          </Link>
        </div>
      </div>
    )
  } else {
    return false
  }
}
