import React from "react"
import { Link } from "gatsby"
import Button from "../button"
import { useScrollPosition } from "../../hooks/use-scroll-position"
import { Spin as Hamburger } from "hamburger-react"
import Logo from "../logo"
import cn from "classnames"
import * as styles from "./header.module.scss"
import ReadingIndicator from "../reading-indicator"
import Share from "../share"

export default function Header({
  showCtaButton,
  mobileStickyScrollPosition,
  readingIndicatorElementRef,
  article,
}) {
  const [isOpen, setOpen] = React.useState(false)
  const [scrollPosition, setScrollPosition] = React.useState({})
  const headerEffectPoint = -100

  useScrollPosition(({ currPos }) => {
    setScrollPosition(currPos)
  })

  return (
    <header
      className={cn(
        styles.header,
        isOpen ? styles.headerOpen : null,
        scrollPosition.y < headerEffectPoint ? styles.isScrolled : null,
        scrollPosition.y < mobileStickyScrollPosition
          ? styles.mobileSticky
          : null
      )}
    >
      {readingIndicatorElementRef && (
        <ReadingIndicator elementRef={readingIndicatorElementRef} />
      )}
      {article && (
        <div
          className={cn(
            styles.articleHeader,
            scrollPosition.y < headerEffectPoint &&
              scrollPosition.readingPercent >= 1
              ? styles.showArticleHeader
              : null
          )}
        >
          <div className={styles.articleHeaderContainer}>
            <p className={styles.articleTitle}>{article.frontmatter.title}</p>
            <Share
              className={styles.shareArticle}
              hideTitle
              slug={`blog/${article.frontmatter.slug}`}
              title={article.frontmatter.title}
            />
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link to="/" aria-label="Grycode Home">
            <Logo />
          </Link>
        </div>
        <nav
          className={cn(styles.nav, isOpen ? styles.navOpen : null)}
          aria-label="Main Navigation"
        >
          <ul className={styles.menu}>
            <li className={styles.menuItem}>
              <Link activeClassName={styles.active} to="/">
                Home
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link activeClassName={styles.active} to="/about">
                About
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                activeClassName={styles.active}
                className={styles.hasSub}
                partiallyActive={true}
                to="/services"
              >
                Services
                <svg width="10" height="6" fill="none">
                  <path d="M1 1l4 4 4-4" stroke="currentColor"></path>
                </svg>
              </Link>
              <ul className={styles.subMenu}>
                <li className={styles.subMenuItem}>
                  <Link to="/services/frontend-development">
                    Frontend Development
                  </Link>
                </li>
                <li className={styles.subMenuItem}>
                  <Link to="/services/user-interface-development">
                    UI/UX Development
                  </Link>
                </li>
                <li className={styles.subMenuItem}>
                  <Link to="/services/jamstack-website">JAMstack website</Link>
                </li>
              </ul>
            </li>
            <li className={styles.menuItem}>
              <Link
                activeClassName={styles.active}
                to="/blog"
                partiallyActive={true}
              >
                Blog
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                activeClassName={styles.active}
                to="/contact"
                partiallyActive={true}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <div className={styles.cta}>
          {!isOpen && (
            <Button
              className={cn(
                styles.ctaButton,
                showCtaButton ? styles.showCtaButton : null
              )}
              to="/start-project"
            >
              Get in touch
            </Button>
          )}
          <span className={styles.hamburger}>
            <Hamburger
              rounded
              toggled={isOpen}
              toggle={setOpen}
              label="Toggle menu"
              distance="sm"
              hideOutline={false}
            />
          </span>
        </div>
      </div>
    </header>
  )
}
