import React from "react"
import { Link as GatsbyLink } from "gatsby"
import cn from "classnames"
import * as styles from "./link.module.scss"

export default function Link({ children, className, ...props }) {
  return (
    <GatsbyLink className={cn(styles.link, className)} {...props}>
      {children}
    </GatsbyLink>
  )
}
