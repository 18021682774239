import React from "react"
import * as styles from "./share.module.scss"
import cn from "classnames"
import useSiteMetadata from "../../hooks/use-site-metadata"

export default function Share({ slug, title, className, hideTitle = false }) {
  const { url } = useSiteMetadata()
  return (
    <div className={cn(styles.share, className)}>
      {!hideTitle && <span className={styles.title}>Share</span>}
      <ul className={styles.socials}>
        <li>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}/${slug}&quote=${title}`}
            target="_blank"
            title="Facebook"
            rel="noopener noreferrer"
          >
            <svg width="10" height="16">
              <path
                fill="currentColor"
                d="M3.023 16L3 9H0V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H10L9 9H6.28v7H3.023z"
                fillRule="evenodd"
              ></path>
            </svg>
          </a>
        </li>
        <li>
          <a
            href={`https://twitter.com/share?url=${url}/${slug}&text=${title}`}
            target="_blank"
            title="Twitter"
            rel="noopener noreferrer"
          >
            <svg width="16" height="13">
              <path
                fill="currentColor"
                d="M16 1.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7C5.2 3.9 2.7 2.6 1.1.6c-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"
                fillRule="evenodd"
              ></path>
            </svg>
          </a>
        </li>
        <li>
          <a
            href={`https://linkedin.com/shareArticle?url=${url}/${slug}`}
            target="_blank"
            title="LinkedIn"
            rel="noopener noreferrer"
          >
            <svg width="17" height="16">
              <path
                fill="currentColor"
                d="M15.3 0H.7C.3 0 0 .3 0 .7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V.7c-.1-.4-.4-.7-.8-.7zM4.7 13.6H2.4V6h2.4v7.6h-.1zM3.6 5c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4V9.9c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8H6.2V6h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z"
                fillRule="evenodd"
              ></path>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  )
}
