import React, { useState } from "react"
import { useScrollPosition } from "../../hooks/use-scroll-position"
import * as styles from "./reading-indicator.module.scss"

export default function ReadingIndicator({ elementRef }) {
  const [readingPercent, setReadingPercent] = useState(100)

  useScrollPosition(
    ({ currPos }) => {
      setReadingPercent(currPos.readingPercent)
    },
    [],
    elementRef
  )

  return (
    <div
      style={{ transform: `translateX(-${readingPercent}%)` }}
      className={styles.readingIndicator}
    ></div>
  )
}
