import React from "react"
import SocialIcons from "../social-icons"
import ThemeSwitcher from "../theme-switcher"
import { Link } from "gatsby"
import * as styles from "./footer.module.scss"

export default function Footer({ children }) {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <button
          type="button"
          onClick={handleScrollToTop}
          className={styles.scrollToTop}
          aria-label="Scroll to top"
        >
          <svg width="16" height="22">
            <path
              d="M8 21V1M1 8l7-7 7 7"
              fill="none"
              stroke="#FFF"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            ></path>
          </svg>
        </button>
        {children}
        <section className={styles.contents}>
          <div>
            <ThemeSwitcher />
          </div>
          <nav className={styles.nav}>
            <div>
              <p>Company</p>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy</Link>
                </li>
                <li>
                  <Link to="/cookie-policy">Cookies</Link>
                </li>
              </ul>
            </div>
            <div>
              <p>Services</p>
              <ul>
                <li>
                  <Link to="/services/frontend-development">
                    Frontend Development
                  </Link>
                </li>
                <li>
                  <Link to="/services/user-interface-development">
                    UI/UX Development
                  </Link>
                </li>
                <li>
                  <Link to="/services/jamstack-website">JAMstack websites</Link>
                </li>
              </ul>
            </div>
            <div>
              <p>Get in touch</p>
              <ul>
                <li>
                  <Link to="/start-project">Start Project</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <SocialIcons className={styles.socialIcons} />
          </nav>
        </section>
        <p className={styles.copyright}>
          © {new Date().getFullYear()} Grycode. All rights reserved. <br />
          Grycode is a trading name of Grycode Ltd. Company No. 12264904.
          Registered office: Kemp House, 152-160 City Road, London, England,
          EC1V 2NX. Registered in England and Wales.
        </p>
      </div>
    </footer>
  )
}
